@import './../theme-bootstrap';

///
///
/// \brief Styles for multi-product pages
///
///

.mpp-container {
  margin-bottom: 30px;
  @include breakpoint($large-up) {
    margin-bottom: 50px;
    max-width: 1070px;
    margin: 0 auto;
  }
}

.mpp-sort {
  max-width: $max-width;
  margin: 0 auto 20px auto;
  padding: 0 10px;
  @include breakpoint($large-up) {
    padding: 0 35px;
    margin: 35px auto;
  }
  .selectBox {
    @include breakpoint($medium-up) {
      min-width: 0;
    }
  }
}

.mpp-sort-menu-selectBox-dropdown-menu {
  min-width: 200px;
}

.mpp-sort__label {
  font-family: $font--avenir-heavy;
  position: relative;
  top: 1px;
  .no-touchevents & {
    right: -10px;
  }
}
